@import '@aurora/shared-client/styles/_variables.pcss';

.lia-container {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;

  @media (--lia-breakpoint-down-md) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.lia-search {
  max-width: 350px;

  @media (--lia-breakpoint-down-md) {
    max-width: none;
    width: 100%;
  }
}
